<template>
  <div>
    <div class="main" :class="!isApp ? 'appTop' : ''">
      <van-tabs
        @click="onChangeTab"
        :color="'#8EE4C8'"
        background="#1C172A"
        line-height="3"
        line-width="24"
        :title-active-color="'#fff'"
        class="gift-tab"
      >
        <van-tab title="积分明细" :name="1">
          <van-list
            v-model:loading="loadinglist"
            :finished="finished"
            @load="getLists"
            @offset="100"
            :finished-text="list.length ? '-到底啦-' : ''"
          >
            <div class="use-item" v-for="(item, index) in list" :key="index">
              <div class="use-head">
                <!-- titleChange(item.transaction_type) -->
                <div class="name">{{ item.opt_desc }}</div>
                <div class="num">{{ item.opt_type == 1 ? '+' + item.got_num : item.cost_num }}</div>
              </div>
              <div class="time">{{ timeChange(item.created_time) }}</div>
            </div>
          </van-list>
        </van-tab>
        <van-tab title="中奖记录" :name="2">
          <van-list
            v-model:loading="luckloading"
            :finished="luckfinished"
            @load="getLuckList"
            @offset="100"
            :finished-text="lucklist.length ? '-到底啦-' : ''"
          >
            <div class="use-item" v-for="(item, index) in lucklist" :key="index">
              <div class="use-head">
                <!-- titleChange(item.transaction_type) -->
                <div class="name">{{ item.reward_desc }}</div>
                <div class="num"></div>
              </div>
              <div class="time">{{ timeChange(item.created_time) }}</div>
            </div>
          </van-list>
        </van-tab>
        <van-tab title="兑换记录" :name="3">
          <van-list
            v-model:loading="exchangeloading"
            :finished="exchangefinished"
            @load="getexchangeList"
            @offset="100"
            :finished-text="exchangelistarr.length ? '-到底啦-' : ''"
          >
            <div class="use-item flex-bt" v-for="(item, index) in exchangelistarr" :key="index">
              <div class="">
                <div class="use-head">
                  <div class="name">{{ item.desc }}</div>
                  <div class="num"></div>
                </div>
                <div class="time">{{ timeChange(item.created_time) }}</div>
              </div>
              <div class="btn" v-if="item.express_status != -1" @click="getAddr(item)">
                {{ item.express_status == 0 ? '填写收货信息' : '查看物流信息' }}
              </div>
            </div>
          </van-list>
        </van-tab>
      </van-tabs>
      <div
        class="no-content"
        v-if="
          (navtype == 1 && list.length == 0) ||
          (navtype == 2 && lucklist.length == 0) ||
          (navtype == 3 && exchangelistarr.length == 0)
        "
      >
        暂无数据
      </div>

      <!-- 地址填写  -->
      <addAddr
        v-model:show.sync="addrShow"
        :order_id="order_id"
        @confirm="
          (exchangelastid = 0), (exchangelistarr = []), getexchangeList(), (addrShow = false)
        "
        @cancel="addrShow = false"
      />

      <!-- 查看物流信息 -->
      <van-popup
        class="vantPop"
        v-model:show="showWuliu"
        closeable
        :close-on-click-overlay="false"
        close-icon-position="top-right"
        @close="showWuliu = false"
      >
        <div class="popup">
          <div class="content">
            <div class="title">物流信息</div>

            <div class="desc">
              <div class="desc-item">
                <div class="">
                  物流单号：{{ wuliuData.express_no ? wuliuData.express_no : '暂无物流信息' }}
                </div>
                <div class="copy" v-if="wuliuData.express_no" @click="copy(wuliuData.express_no)">
                  复制
                </div>
              </div>
              <div class="desc-item">
                <div class="">客服电话：{{ wuliuData.service_tel }}</div>
                <div class="copy" @click="copy(wuliuData.service_tel)">复制</div>
              </div>
            </div>
            <div class="btn">
              <div @click="showWuliu = false">知道了</div>
            </div>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue';
import { getscoredetails } from '@/service/order.js';
import { drawlist, exchangelist } from '@/service/point.js';
import useClipboard from 'vue-clipboard3';
import moment from 'moment';
import { nftUtils } from '@/utils';
import addAddr from '@/components/addAddr.vue';
import { Toast } from 'vant';

export default {
  components: {
    addAddr
  },
  setup() {
    const { toClipboard } = useClipboard();
    const state = reactive({
      size: 20,
      lastid: 0,
      list: [],
      finished: false, //all
      loadinglist: false,
      luckfinished: false, //中奖记录
      luckloading: false, //中奖记录
      lucklist: [],
      lucklastid: 0,
      exchangefinished: false, //兑换记录
      exchangeloading: false, //兑换记录
      exchangelistarr: [],
      exchangelastid: 0,
      isApp: nftUtils.isInApp(),
      navtype: 1,
      addrShow: false,
      showWuliu: false, //显示物流信息
      order_id: null,
      wuliuData: {}
    });
    onMounted(async () => {
      // getList();
    });
    const getLists = async () => {
      const { data } = await getscoredetails({ lastid: state.lastid });
      // state.list = state.list.concat(data);
      state.loadinglist = false;
      state.list = state.list.concat(data);
      if (data.length == 0) {
        state.finished = true;
      } else {
        state.lastid = data[data.length - 1].id;
      }
    };

    //复制
    const copy = async val => {
      try {
        await toClipboard(val);
        console.log('复制成功!');
        Toast({ message: '复制成功！' });
      } catch (e) {
        console.log(e);
      }
    };

    //填写、查看物流信息
    const getAddr = item => {
      // 是否填写地址 -- 发货状态（-1 不需要，0 未填收货信息，1 已发货（无物流信息），2 已发货（有物流信息））
      if (item.express_status == 0) {
        state.order_id = item.id;
        state.addrShow = true;
      } else if (item.express_status == 1 || item.express_status == 2) {
        state.wuliuData = item;
        state.showWuliu = true;
      }
    };
    const getLuckList = async () => {
      const { data } = await drawlist({ lastid: state.lucklastid });
      // state.list = state.list.concat(data);
      state.luckloading = false;
      state.lucklist = state.lucklist.concat(data);
      if (data.length == 0) {
        state.luckfinished = true;
      } else {
        state.lucklastid = data[data.length - 1].id;
      }
    };
    const getexchangeList = async () => {
      const { data } = await exchangelist({ lastid: state.exchangelastid });
      console.log(1111, data);
      state.exchangeloading = false;
      state.exchangelistarr = state.exchangelistarr.concat(data);
      console.log(data, '...data');
      if (data.length == 0) {
        state.exchangefinished = true;
      } else {
        state.exchangelastid = data[data.length - 1].id;
      }
    };
    const timeChange = time => {
      return moment(time).format('YYYY-MM-DD HH:mm');
    };

    const onChangeTab = name => {
      state.navtype = name;
      if (name == 2 && state.luckfinished) {
        console.log(11);
      }
    };

    const titleChange = type => {
      switch (type) {
        case 1:
          return '分解藏品';
        case 2:
          return '商品购买抵扣';
        case 3:
          return '退款获得';
        case 4:
          return '抽奖使用';
        case 5:
          return '抽奖获得';
      }
    };
    return {
      getLists,
      titleChange,
      timeChange,
      onChangeTab,
      getLuckList,
      getexchangeList,
      getAddr,
      copy,
      ...toRefs(state)
    };
  }
};
</script>
<style lang="scss">
.main {
  .van-tab--active {
    font-size: 16px;
  }
}
</style>

<style lang="scss" scoped>
@import '../styles/mixin.scss';
.popup {
  width: 300px;
  background: #2f293f;
  border-radius: 8px 8px 8px 8px;

  color: #ffffff;
  font-size: 16px;

  .content {
    padding: 16px 20px 26px 20px;

    .title {
      font-size: 18px;
      font-weight: 500;
      text-align: center;
    }
    .desc {
      font-weight: 400;
      margin-top: 20px;
      margin-bottom: 34px;
      font-size: 14px;
      .desc-item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        .copy {
          color: $primary;
        }
      }
    }
  }
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 116px;
    height: 36px;
    background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
    border-radius: 8px 8px 8px 8px;
    margin: 0px auto;
    font-weight: 500;

    color: #1c172a;
  }
}
.no-content {
  text-align: center;
  margin-top: 100px;
  font-size: 14px;
}
.gift-tab {
  font-size: 14px;
  margin-bottom: 6px;
}
.main {
  padding: 16px;
}
.appTop {
  padding-top: 70px;
}
* {
  box-sizing: border-box;
}
.flex-bt {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.use-item {
  padding: 10px 0;
  border-bottom: 1px solid #494359;
  .btn {
    min-width: 94px;
    width: 94px;
    height: 32px;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }
  .use-head {
    display: flex;
    justify-content: space-between;
    .name {
      font-size: 14px;
    }
    .num {
      font-size: 16px;
      font-weight: 600;
    }
  }
  .time {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.7);
    line-height: 26px;
  }
}
</style>
